<template>
  <section id="talk">
    <v-card
      class="mx-auto py-6 mb-0"
      elevation="24"
      light
      max-width="95%"
      style="margin-top: -64px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet max-width="1000" class="mx-auto py-0 py-md-6">
        <section>
          <v-container>
            <v-row justify="center">
              <v-col
                v-for="([icon, info1, info2, href], i) in details"
                :key="i"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <a
                    style="text-decoration: none;"
                    :href="href"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-icon
                      color="thirdary"
                      size="64"
                      class="mb-3"
                      v-text="icon"
                    />

                    <div color="thirdary" class="mb-2 title" v-text="info1" />
                    <div color="thirdary" v-text="info2" />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <v-divider class="mt-5 mb-4 mx-auto" />
        <v-container>
          <v-row justify="space-between">
            <v-col cols="12" md="6">
              <!-- Constantia uitsig  -->
              <div class="section-header mb-4">
                <h2
                  :class="[
                    $vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'
                  ]"
                  class="mb-4"
                >
                  Twin Streams Nursery
                </h2>
              </div>

              <p class="subtitle-1">
                <span class="font-weight-bold">Phone:</span> 074 189 6354
                <br />
                <span class="font-weight-bold">Email:&nbsp;</span>
                <a href="mailto:info@twinstreams.co.za"
                  >info@twinstreams.co.za</a
                >
                <!-- <a :href="`mailto:${sol.email}`">{{ sol.email }}</a> -->
                <!-- <br />
                <span class="font-weight-bold">Adress:</span> Spaanschemat River
                Road, Constantia, Cape Town, 7806
                this is incorrect  -->
                <br />
                <span class="font-weight-bold">Hours:</span>
                Monday: Closed. <br />
                Tuesday – Friday: 07:00-16:00 <br />
                Saturday & Sunday 07:00-13:00
                <br />
                <br />

                <v-btn
                  v-for="([href, text, name], i) in cusocials"
                  :key="`social-${i}`"
                  :href="href"
                  class="px-0 mx-2"
                  color="thirdary"
                  text
                  small
                  light
                  min-width="48"
                  rel="noopener"
                  target="_blank"
                >
                  <v-icon class="mr-2" v-text="text" />
                  <span class="hidden-sm-and-down" v-text="name" />
                </v-btn>
              </p>
              <div class="section-header mb-4">
                <h2
                  :class="[
                    $vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'
                  ]"
                  class="mb-4"
                >
                  Events Contact
                </h2>
              </div>

              <p class="subtitle-1">
                <span class="font-weight-bold">Phone:</span> 082 560 5532
                <br />
                <span class="font-weight-bold">Email:&nbsp;</span>
                <a href="mailto:dawn@mykzn.co.za ">dawn@mykzn.co.za </a>
                <br />
                <span class="font-weight-bold">Hours:</span>
                Monday: Closed. <br />
                Tuesday – Friday: 07:30-16:00 <br />
                Saturday & Sunday 07:30-13:00
                <br />
              </p>

              <div class="section-header mb-4">
                <h2
                  :class="[
                    $vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'
                  ]"
                  class="mb-4"
                >
                  Under the Toad Tree Restaurant
                </h2>
              </div>

              <p class="subtitle-1">
                <span class="font-weight-bold">Phone:</span> 082 560 5532
                <br />
                <span class="font-weight-bold">Email:&nbsp;</span>
                <a href="mailto:info@twinstreams.co.za"
                  >info@twinstreams.co.za</a
                >
                <br />
                <span class="font-weight-bold">Hours:</span>
                Monday: Closed. <br />
                Tuesday – Friday: 07:30-16:00 <br />
                Saturday & Sunday 07:30-13:00
                <br />
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <div class="section-header mb-7">
                <!-- second side -->

                <h2
                  :class="[
                    $vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'
                  ]"
                  class="mb-4"
                >
                  Directions
                </h2>
              </div>

              <v-card width="480px" height="515px">
                <v-img
                  width="100%"
                  height="515px"
                  :src="require('@/assets/Main/directions.png')"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-card>

    <section>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8997.569354987383!2d31.727023829587164!3d-28.988369759018756!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1ef24fea994c6bf!2sTwinstreams%20Indigenous%20Nursery!5e1!3m2!1sen!2sza!4v1610543402094!5m2!1sen!2sza"
        width="100%"
        height="700"
        frameborder="0"
        style="border:0; padding-bottom: 60px;"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      />
    </section>
  </section>
</template>

<script>
export default {
  name: "ContactContents",

  data: () => ({
    details: [
      [
        "mdi-crosshairs-gps",
        "Twinstreams Indigenous Nursery",
        "Mtunzini, KZN, 3867",
        "https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617"
      ],
      [
        "mdi-email",
        "info@twinstreams.co.za ",
        "",
        "mailto:info@twinstreams.co.za "
      ],
      ["mdi-phone", "074 189 6354", "", "tel:+27-74-189-6354"]
    ],

    cusocials: [
      [
        "https://www.facebook.com/Twinstreamsindigenousnursery",
        "mdi-facebook",
        "Facebook"
      ],

      [
        "https://www.instagram.com/twinstreams_nursery/",
        "mdi-instagram",
        "Instagram"
      ]
    ]
  })
};
</script>

<style scoped>
.none {
  text-decoration: none;
  font-size: large;
  font-weight: bold;
}

.v-card {
  border-radius: 0px;
}
</style>
