var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"talk"}},[_c('v-card',_vm._b({staticClass:"mx-auto py-6 mb-0",staticStyle:{"margin-top":"-64px"},attrs:{"elevation":"24","light":"","max-width":"95%","tile":"","outlined":""}},'v-card',_vm.$attrs,false),[_c('v-sheet',{staticClass:"mx-auto py-0 py-md-6",attrs:{"max-width":"1000"}},[_c('section',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.details),function(ref,i){
var icon = ref[0];
var info1 = ref[1];
var info2 = ref[2];
var href = ref[3];
return _c('v-col',{key:i,attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-center"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":href,"target":"_blank","rel":"noopener"}},[_c('v-icon',{staticClass:"mb-3",attrs:{"color":"thirdary","size":"64"},domProps:{"textContent":_vm._s(icon)}}),_c('div',{staticClass:"mb-2 title",attrs:{"color":"thirdary"},domProps:{"textContent":_vm._s(info1)}}),_c('div',{attrs:{"color":"thirdary"},domProps:{"textContent":_vm._s(info2)}})],1)])])}),1)],1)],1),_c('v-divider',{staticClass:"mt-5 mb-4 mx-auto"}),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"section-header mb-4"},[_c('h2',{staticClass:"mb-4",class:[
                  _vm.$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'
                ]},[_vm._v(" Twin Streams Nursery ")])]),_c('p',{staticClass:"subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Phone:")]),_vm._v(" 074 189 6354 "),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Email: ")]),_c('a',{attrs:{"href":"mailto:info@twinstreams.co.za"}},[_vm._v("info@twinstreams.co.za")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Hours:")]),_vm._v(" Monday: Closed. "),_c('br'),_vm._v(" Tuesday – Friday: 07:00-16:00 "),_c('br'),_vm._v(" Saturday & Sunday 07:00-13:00 "),_c('br'),_c('br'),_vm._l((_vm.cusocials),function(ref,i){
                var href = ref[0];
                var text = ref[1];
                var name = ref[2];
return _c('v-btn',{key:("social-" + i),staticClass:"px-0 mx-2",attrs:{"href":href,"color":"thirdary","text":"","small":"","light":"","min-width":"48","rel":"noopener","target":"_blank"}},[_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s(text)}}),_c('span',{staticClass:"hidden-sm-and-down",domProps:{"textContent":_vm._s(name)}})],1)})],2),_c('div',{staticClass:"section-header mb-4"},[_c('h2',{staticClass:"mb-4",class:[
                  _vm.$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'
                ]},[_vm._v(" Events Contact ")])]),_c('p',{staticClass:"subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Phone:")]),_vm._v(" 082 560 5532 "),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Email: ")]),_c('a',{attrs:{"href":"mailto:dawn@mykzn.co.za "}},[_vm._v("dawn@mykzn.co.za ")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Hours:")]),_vm._v(" Monday: Closed. "),_c('br'),_vm._v(" Tuesday – Friday: 07:30-16:00 "),_c('br'),_vm._v(" Saturday & Sunday 07:30-13:00 "),_c('br')]),_c('div',{staticClass:"section-header mb-4"},[_c('h2',{staticClass:"mb-4",class:[
                  _vm.$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'
                ]},[_vm._v(" Under the Toad Tree Restaurant ")])]),_c('p',{staticClass:"subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Phone:")]),_vm._v(" 082 560 5532 "),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Email: ")]),_c('a',{attrs:{"href":"mailto:info@twinstreams.co.za"}},[_vm._v("info@twinstreams.co.za")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Hours:")]),_vm._v(" Monday: Closed. "),_c('br'),_vm._v(" Tuesday – Friday: 07:30-16:00 "),_c('br'),_vm._v(" Saturday & Sunday 07:30-13:00 "),_c('br')])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"section-header mb-7"},[_c('h2',{staticClass:"mb-4",class:[
                  _vm.$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'
                ]},[_vm._v(" Directions ")])]),_c('v-card',{attrs:{"width":"480px","height":"515px"}},[_c('v-img',{attrs:{"width":"100%","height":"515px","src":require('@/assets/Main/directions.png')}})],1)],1)],1)],1)],1)],1),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('iframe',{staticStyle:{"border":"0","padding-bottom":"60px"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8997.569354987383!2d31.727023829587164!3d-28.988369759018756!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1ef24fea994c6bf!2sTwinstreams%20Indigenous%20Nursery!5e1!3m2!1sen!2sza!4v1610543402094!5m2!1sen!2sza","width":"100%","height":"700","frameborder":"0","allowfullscreen":"","aria-hidden":"false","tabindex":"0"}})])}]

export { render, staticRenderFns }